'use client';

import { useRouter } from 'next/navigation';
import { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'sonner';

import { registerAutoLogin } from 'lib/autologin';
import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';

import GoogleLogo from 'public/images/icons/google-g-logo-mono.svg';

import Button from 'components/Button/NewButton';
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Input/NewInput';
import PasswordError from 'components/PasswordError';

import { createAccountSchema } from 'utils/validationSchemas';
import { genericErrorMap } from 'utils/genericError';
import { fetchWithCredentials } from 'utils/fetchWrapper';
import ServerAPIError from 'utils/ServerAPIError';

import st from './CreateAccountForm.module.scss';
import { DividerText } from 'components/DividerText/DividerText';

const defaultFieldErrors = {
  email: [],
  password: [],
};

interface Props {
  onLoad: (isLoading: boolean) => void;
  passwordErrorVariant?: 'toast' | 'embedded';
}

export default function CreateAccountForm({
  onLoad,
  passwordErrorVariant = 'toast',
}: Props) {
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fieldErrors, setFieldErrors] = useState<{
    email?: string[];
    password?: string[];
  }>(defaultFieldErrors);
  const [genericError, setGenericError] = useState('');

  async function validate() {
    const validation = createAccountSchema.safeParse({ email, password });

    if (!validation.success) {
      const errors = validation?.error?.flatten();
      if (
        Boolean(errors?.fieldErrors?.password?.length) &&
        passwordErrorVariant === 'toast'
      ) {
        toast.error(<PasswordError />, {
          duration: 60000,
          position: 'top-center',
        });
      }
      setFieldErrors(errors?.fieldErrors);

      return false;
    }

    try {
      const response = await fetchWithCredentials(
        `${
          process.env.NEXT_PUBLIC_SERVER_BASE_URL
        }/utils/is-valid-domain?email=${encodeURIComponent(email)}`,
      );
      const resJson = await response.json();

      if (!response.ok) {
        throw new ServerAPIError(
          `${resJson?.error?.message ?? 'Unexpected error'}`,
          response,
        );
      }

      setFieldErrors(defaultFieldErrors);
      return true;
    } catch (err) {
      const fieldErrors = {
        email: ['Please enter your work email or contact hi@cleeai.com'],
        password: [],
      };

      setFieldErrors(fieldErrors);

      return false;
    }
  }

  function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    const { name, value } = ev.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  }

  async function handleSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    setGenericError('');
    onLoad(true);

    const isValid = await validate();
    if (!isValid) {
      onLoad(false);
      return;
    }

    try {
      await trpc.auth.signUp.mutate({ email, password });
      await registerAutoLogin(email, password);
      analytics.preverified(email);
      router.push(`/verify?email=${encodeURIComponent(email)}`);
    } catch (err) {
      const genericError =
        genericErrorMap[err.message] ??
        'There has been a problem, please try again';
      setGenericError(genericError);
      console.error('error signing up:', err);
    } finally {
      onLoad(false);
    }
  }

  return (
    <>
      <div className={st.textContainer}>
        <h2 className={st.title}>Access to unlock more features</h2>
        <h3 className={st.subtitle}>Sign in or sign up to continue</h3>
      </div>
      <div className={st.formContainer}>
        <Button
          type="submit"
          isFullWidth
          variant={'primary'}
          className={st.googleBtn}
          onClick={async () => {
            const params = new URLSearchParams({
              redirect_uri: process.env.NEXT_PUBLIC_OAUTH_REDIRECT || '',
              response_type: 'code',
              client_id: '',
              identity_provider: 'Google',
              scope: '',
              state: 'state',
              code_challenge: '',
              code_challenge_method: 'S256',
            });
            router.push(
              `http://${process.env.NEXT_PUBLIC_OAUTH_DOMAIN}/oauth2/authorize?${params.toString()}`,
            );
          }}
        >
          <GoogleLogo alt="Forward arrow icon" width={20} height={20} />
          Continue with Google
        </Button>
        <DividerText>Sign up with email</DividerText>
        <form onSubmit={handleSubmit} noValidate>
          <fieldset>
            <Input
              type="email"
              name="email"
              id="email"
              label={'Work email'}
              defaultValue={email}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.email?.length)}
              errorMessage={fieldErrors?.email?.[0] ?? ''}
            />
            <Input
              type="password"
              name="password"
              id="password"
              label={'Password'}
              defaultValue={password}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.password?.length)}
              errorMessage={fieldErrors?.password?.[0] ?? ''}
            />
            {Boolean(genericError) && (
              <ErrorMessage className={st.genericError}>
                {genericError}
              </ErrorMessage>
            )}
          </fieldset>
          <Button type="submit" isFullWidth variant={'tertiary'}>
            Sign up
          </Button>
        </form>
        {Boolean(fieldErrors?.password?.length) &&
        passwordErrorVariant === 'embedded' ? (
          <PasswordError />
        ) : null}
      </div>
    </>
  );
}
