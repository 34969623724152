import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import Link from 'next/link';
import st from './NewButton.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'button';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  isFullWidth?: boolean;
  size?: 'large' | 'small';
  shape?: 'rounded' | 'pill' | 'circle';
  align?: 'center' | 'left' | 'right' | 'start' | 'end';
  children: string | ReactNode;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  href?: string;
  disabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  function Button(
    {
      type = 'submit',
      variant = 'primary',
      isFullWidth = false,
      size = 'large',
      shape = 'rounded',
      align = 'center',
      children,
      className,
      onClick,
      href = '',
      disabled,
      ...attr
    }: Props,
    ref,
  ) {
    if (Boolean(href) && !disabled) {
      return (
        <Link
          href={href}
          passHref
          onClick={onClick}
          className={classNames(st.button, className)}
          data-variant={variant}
          data-shape={shape}
          data-align={align}
          data-size={size}
          data-is-full-width={isFullWidth}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          {children}
        </Link>
      );
    }

    return (
      <button
        type={type}
        onClick={onClick}
        className={classNames(st.button, className)}
        data-variant={variant}
        data-shape={shape}
        data-align={align}
        data-size={size}
        data-is-full-width={isFullWidth}
        disabled={disabled}
        ref={ref as React.Ref<HTMLButtonElement>}
        {...attr}
      >
        {children}
      </button>
    );
  },
);

export default Button;
